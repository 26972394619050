import { Box, Collapse, darken, lighten, makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { Color } from "@material-ui/lab";
import clsx from "clsx";
import { FC } from "react";

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    content: {
      ...theme.typography.body2,
      backgroundColor: theme.colors.softGrey,
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      justifyContent: "center",
      lineHeight: 1.3,
      minHeight: 42,
      padding: theme.spacing(),
      textAlign: "center",
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(1, 2),
      },

      "& strong": {
        display: "inline-block",
        margin: theme.spacing(0, 0.5),
        padding: theme.spacing(0, 0.5),
        borderRadius: theme.spacing(0.5),
        backgroundColor: theme.colors.warning.main,
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    big: {
      fontSize: 16,
    },
    small: {},
    success: {
      backgroundColor: lighten(theme.palette.success.light, 0.8),
      color: darken(theme.palette.success.dark, 0.5),
      "& a": {
        color: darken(theme.palette.success.dark, 0.5),
      },
    },
    info: {
      backgroundColor: lighten(theme.palette.info.main, 0.85),
      color: darken(theme.palette.info.main, 0.5),
      "& a": {
        color: darken(theme.palette.info.main, 0.5),
      },
    },
    warning: {
      backgroundColor: theme.palette.warning.light,
      color: theme.palette.warning.dark,
      "& a": {
        color: theme.palette.warning.dark,
      },
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.9),
      color: theme.palette.error.dark,
      "& a": {
        color: theme.palette.error.dark,
      },
    },
    dark: {
      backgroundColor: "#2f2e41",
      color: theme.palette.common.white,

      "& a": {
        color: theme.colors.logo.shrimp,
      },
    },
  }),
  {
    classNamePrefix: "Banner",
  }
);

export type BannerJSSClassKey = keyof ReturnType<typeof useStyles>;

export type BannerVariant = Color | "dark" | "default";

export type BannerProps = {
  classes?: Partial<ClassNameMap<BannerJSSClassKey>>;
  className?: string;
  size?: "big" | "small";
  variant?: BannerVariant;
  in?: boolean;
};

export const Banner: FC<BannerProps> = ({
  className,
  classes: extClasses,
  children,
  in: inProp,
  size = "small",
  variant = "default",
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  return (
    <Collapse in={inProp} className={clsx(classes.root, className)}>
      <Box
        className={clsx(classes.content, {
          [classes.big]: size === "big",
          [classes.small]: size === "small",
          [classes.dark]: variant === "dark",
          [classes.success]: variant === "success",
          [classes.info]: variant === "info",
          [classes.warning]: variant === "warning",
          [classes.error]: variant === "error",
        })}
      >
        <Box>{children}</Box>
      </Box>
    </Collapse>
  );
};
